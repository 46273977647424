import { createContext, useContext, useState, useMemo } from "react";

const userContext = createContext({
  username: "",
  changeUsername: (value: string) => {},
  UUID: "",
  changeUUID: (value: string) => {},
  choice: 0,
  changeChoice: (value: number) => {},
  spectator: false,
  changeSpectator: (value: boolean) => {},
  room: "",
  changeRoom: (value: string) => {},
});

const UserContextProvider = ({ children }: any) => {
  const [username, setUsername] = useState("");
  const [UUID, setUUID] = useState("");
  const [choice, setChoice] = useState(0);
  const [spectator, setSpectator] = useState(false);
  const [room, setRoom] = useState("");

  const changeUsername = useMemo(
    () => (value: string) => {
      setUsername(value);
    },
    []
  );

  const changeUUID = useMemo(
    () => (value: string) => {
      setUUID(value);
    },
    []
  );

  const changeChoice = useMemo(
    () => (value: number) => {
      setChoice(value);
    },
    []
  );

  const changeSpectator = useMemo(
    () => (value: boolean) => {
      if (value !== spectator) {
        setSpectator(value);
      }
    },
    [spectator]
  );

  const changeRoom = useMemo(
    () => (value: string) => {
      if (room !== value) {
        setRoom(value);
      }
    },
    [room]
  );

  const values = useMemo(() => ({ username, changeUsername, UUID, changeUUID, spectator, changeSpectator, room, changeRoom, choice, changeChoice }), [username, UUID, spectator, room, choice, changeUsername, changeUUID, changeSpectator, changeRoom, changeChoice]);

  return <userContext.Provider value={values}>{children}</userContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(userContext);

  if (context === undefined) {
    throw new Error("useUserContext was used outside of its Provider");
  }

  return context;
};

export { userContext, UserContextProvider, useUserContext };
