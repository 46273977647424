import React from "react";
import "./Tooltip.scss";

function Bug() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(1 1)">
            <rect
              width="14"
              height="14"
              x="0"
              y="0"
              fill="#E5493A"
              rx="2"
            ></rect>
            <path fill="#FFF" d="M10 7a3 3 0 11-6 0 3 3 0 016 0"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

function Epic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(1 1)">
            <rect
              width="14"
              height="14"
              x="0"
              y="0"
              fill="#904EE2"
              rx="2"
            ></rect>
            <g fill="#FFF" transform="translate(4 3)">
              <path d="M5.923 3.757l-.002-.004a.479.479 0 00.08-.253.5.5 0 00-.5-.5H3V.5a.5.5 0 00-.5-.5.49.49 0 00-.406.223.498.498 0 00-.066.13L.081 4.237l.002.003A.489.489 0 000 4.5a.5.5 0 00.5.5H3v2.5a.493.493 0 00.918.259h.003l.013-.025c.011-.021.023-.04.031-.062l1.958-3.915z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

function Story() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(1 1)">
            <rect
              width="14"
              height="14"
              x="0"
              y="0"
              fill="#63BA3C"
              rx="2"
            ></rect>
            <path
              fill="#FFF"
              d="M9 3H5a1 1 0 00-1 1v6.5a.5.5 0 00.5.5.49.49 0 00.41-.231l.004.001L6.84 8.54a.2.2 0 01.32 0l1.926 2.23.004-.001A.49.49 0 009.5 11a.5.5 0 00.5-.5V4a1 1 0 00-1-1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

function SubTask() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(1 1)">
            <rect
              width="14"
              height="14"
              x="0"
              y="0"
              fill="#4BAEE8"
              rx="2"
            ></rect>
            <rect
              width="5"
              height="5"
              x="3"
              y="3"
              stroke="#FFF"
              rx="0.8"
            ></rect>
            <rect
              width="5"
              height="5"
              x="6"
              y="6"
              fill="#FFF"
              stroke="#FFF"
              rx="0.8"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}

function Task() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(1 1)">
            <rect
              width="14"
              height="14"
              x="0"
              y="0"
              fill="#4BADE8"
              rx="2"
            ></rect>
            <g
              stroke="#FFF"
              strokeLinecap="round"
              strokeWidth="2"
              transform="translate(4 4.5)"
            >
              <path d="M2 5l4-5"></path>
              <path d="M2 5L0 3"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}



const IssueType = (props: any) => {
  const { issue } = props;

  let issueType = Story();

  switch (issue.fields.issuetype.name) {
    case "Bug":
      issueType = Bug();
      break;
    case "Epic":
      issueType = Epic();
      break;
    case "SubTask":
      issueType = SubTask();
      break;
    case "Task":
      issueType = Task();
      break;
    default:
      issueType = Story();
  };

  return (
    <div style={{ marginTop: "2px" }} className="tooltip">
      {issueType}
      <span className="tooltiptext">{issue.fields.issuetype.name}</span>
    </div>
  );
};

export default IssueType;