export interface TeamMember {
    name: string;
    image: string;
    role: string;
    linkedIn: string;
    gitHub: string;
}

interface OurTeam {
    [member: string]: TeamMember
}

const Team: OurTeam = {
    "Haolin": {
        name: "Haolin Wu",
        image: "https://cdn.discordapp.com/attachments/1027186342620299315/1099288000317374475/DiscordPTB_5tPLyCmrRU.png",
        role: "Co-Founder",
        linkedIn: "https://www.linkedin.com/in/haolin-wu-222188160/",
        gitHub: "https://github.com/Dragonite"
    },
    "Joel": {
        name: "Joel Wilkinson",
        image: "https://media.discordapp.net/attachments/1027186342620299315/1099705099443511356/DiscordPTB_dOqVzDyvjr.png",
        role: "Co-Founder",
        linkedIn: "https://www.linkedin.com/in/joel-wilkinson-548207165/",
        gitHub: "https://github.com/JoelWi"
    }
}

export default Team