import { useWebSocketContext } from "../../../Contexts/WebSocket";
import { Player } from '@lottiefiles/react-lottie-player';
import { isDesktop, Size } from "../../../Common/Utils";
import Ecstatic from '../../Lotties/ecstatic.json';
import Devastated from '../../Lotties/devastated.json';
import Dissapointed from '../../Lotties/dissapointed.json';
import Happy from '../../Lotties/happy.json';
import Neutral from '../../Lotties/neutral.json';
import 'react-circular-progressbar/dist/styles.css';
import './Result.scss'

interface ResultProps {
  size: Size;
}

const Result = ({ size }: ResultProps) => {
  const { users, reveal } = useWebSocketContext();

  function calculateHeight(frequency: number, voteCount: number) {
    let height = frequency / voteCount * 100;
    return height + "%";
  }

  const renderScore = () => {
    let totalScore = 0;
    // My thoughts here are that we find the frequency of each score, then the highest frequency/total amount of scores becomes the 'quality' we give the final score.
    let frequencyArray: any = {};
    let highestFrequency: number = 0;
    let totalVotes: number = 0;
    if (users.users !== undefined && users.users.length > 0) {
      for (const user of users.users) {
        if (user['visible'] === 0 && user['spectator'] !== false) {
          totalVotes += 1;
          totalScore += Number(user['choice']);
          frequencyArray[user['choice']] = (frequencyArray[user['choice']] || 0) + 1;
        }
      }
      const frequencyJSArray = []
      for (const key of Object.keys(frequencyArray)) {
        frequencyJSArray.push({ [key]: frequencyArray[key] });
        if (frequencyArray[key] > highestFrequency) {
          highestFrequency = frequencyArray[key];
        }
      }


      frequencyJSArray.sort((a, b) => {
        const aKey = Object.keys(a)[0];
        const bKey = Object.keys(b)[0];
        return b[bKey] - a[aKey]
      });

      const renderResults = (frequencys: any) => {
        if (frequencys.length > 0) {
          return frequencys.map((frequency: any, index: number) => (
            <div key={Object.keys(frequency)[0]} style={{ display: "flex", flexDirection: "column", height: "100%", marginLeft: "10px", marginRight: "10px" }}>
              <div style={{ height: "50%", display: "flex", flexDirection: "column" }}>
                <div style={{ height: calculateHeight(frequency[Object.keys(frequency)[0]], totalVotes), margin: "auto auto 5px auto", width: "12px", backgroundColor: "#001e82", borderRadius: "10px" }}>
                </div>
              </div>
              <div style={{ marginTop: "auto" }}>
                <div className="result__card__visible" style={{ margin: "0 auto" }}>
                  <strong>{Object.keys(frequency)[0]}</strong>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h4 style={{ marginTop: "10px", marginBottom: "0px" }}>{frequency[Object.keys(frequency)[0]] > 1 ? frequency[Object.keys(frequency)[0]] + " Votes" : frequency[Object.keys(frequency)[0]] + " Vote"}</h4>
                </div>
              </div>
            </div>
          ));
        }
      };

      const renderMobileResults = (frequencys: any) => {
        if (frequencys.length > 0) {
          return frequencys.map((frequency: any, index: number) => (
            <li className="py-3 last:pb-0 first:pt-0" key={`mobile_frequency_${index}`}>
              <div className="flex items-center space-x-4">
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  <div className="w-12 h-16 bg-blue-300 rounded-md flex">
                    <div className="text-black text-2xl text-center m-auto">{Object.keys(frequency)[0]}</div>
                  </div>
                </div>
                <div className="flex-1 min-w-0">
                  <div className="text-sm font-medium text-gray-900 truncate">
                    <div className="mb-2">{frequency[Object.keys(frequency)[0]] > 1 ? frequency[Object.keys(frequency)[0]] + " Votes" : frequency[Object.keys(frequency)[0]] + " Vote"}</div>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${calculateHeight(frequency[Object.keys(frequency)[0]], totalVotes)}` }}></div>
                  </div>
                </div>

              </div>
            </li>
          ));
        }
      };

      const average = totalScore > 0 && reveal ? Math.round((totalScore / totalVotes) * 10) / 10 : 0;
      const agreement = highestFrequency / totalVotes;

      const getPupFromAgreement = (value: number) => {
        if (value <= 0.25) {
          return Devastated;
        } else if (value > 0.25 && value < 0.5) {
          return Dissapointed;
        } else if (value >= 0.5 && value < 0.75) {
          return Neutral;
        } else if (value >= 0.75 && value <= 0.99) {
          return Happy;
        }
        return Ecstatic;
      }

      return (
        <>
          {isDesktop(size) ? (
            <div className="result-container" >
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              </div>
              <div>
                <div className="ScrollStyle">
                  {renderResults(frequencyJSArray)}
                </div>
              </div>
              <div style={{ width: '20%', textAlign: "center" }}>
                <div>
                  <div><h3 style={{ marginTop: '0', marginBottom: '0' }}>Average</h3></div>
                  <div style={{ fontSize: '20px' }}>
                    <strong>{average}</strong>
                  </div>
                </div>
                <div style={{ margin: '0 auto' }}>
                  <Player
                    src={getPupFromAgreement(agreement)}
                    style={{ height: '120px', width: '120px' }}
                    autoplay
                    keepLastFrame
                  >
                  </Player>
                </div>

              </div>
            </div >
          ) : (
            <div className="px-8">
              <div className="flex flex-wrap gap-8">
                <div className="m-auto text-center">
                  <div>
                    <div>
                      <h3 style={{ marginTop: '0', marginBottom: '0' }}>Average</h3></div>
                    <div style={{ fontSize: '20px' }}>
                      <strong>{average}</strong>
                    </div>
                  </div>
                  <Player
                    src={getPupFromAgreement(agreement)}
                    style={{ height: '120px', width: '120px', display: 'block' }}
                    autoplay
                    keepLastFrame
                  >
                  </Player>
                </div>
                <div className="bg-white grow rounded-xl p-4" style={{ minWidth: "12rem" }}>
                  <ul>
                    {renderMobileResults(frequencyJSArray)}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  };

  return <div>{renderScore()}</div>;
};

export default Result;
