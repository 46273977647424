import { useEffect, useState } from "react";

import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Team from "../../Common/Team";
import Member from "./Member/Member";
import { setWindowTitle } from "../../Common/Utils";

import '../Poker/Username/Modal.scss';

const OurTeam = () => {

    setWindowTitle('Our Team');

    const [statusMessage, setStatusMessage] = useState<null | number>(null);
    const [contactError, setContactError] = useState<boolean>(false);
    const [reasonError, setReasonError] = useState<boolean>(false);
    const [contact, setContact] = useState<string>('');
    const [reason, setReason] = useState<string>('');
    const [disabledSend, setDisabledSend] = useState<boolean>(false);

    const toggleModal = (modalClass: string) => {
        setContactError(false);
        setReasonError(false);
        let modal = document.getElementById(modalClass);
        if (modal !== undefined && modal !== null) {
            modal.style.display = "block";
            modal.classList.add("modal-open");
        }
    };

    const closeModal = (modalClass: string) => {
        let modal = document.getElementById(modalClass);
        if (modal !== undefined && modal !== null) {
            modal.style.display = "none";
        }
    };

    const handleSend = async () => {
        if (!contact || !reason) {
            if (!contact) {
                setContactError(true);
            }
            if (!reason) {
                setReasonError(true);
            }
            return;
        } else {
            setContactError(false);
            setReasonError(false);
        }
        if (!disabledSend) {
            const response = await fetch('/api/contact', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                headers: {
                    "Content-Type": "application/json",
                    "source": "archee"
                },
                body: JSON.stringify({
                    contact, reason
                }), // body data type must match "Content-Type" header
            });
            if (response.status === 200) {
                setStatusMessage(1);
                setDisabledSend(true);
                setTimeout(() => {
                    setStatusMessage(null);
                    setDisabledSend(false);
                }, 5000);
            } else {
                setStatusMessage(2);
                setTimeout(() => {
                    setStatusMessage(null);
                }, 3000);
            }
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <Navbar selected={3} right={true} />
            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div id="contactUsModal" style={{ display: "none" }}>
                    <div id="contactUsModalContainer" className="modal">
                        {statusMessage ? (
                            <div className="absolute right-4 bottom-24 sm:top-24 sm:bottom-auto flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                                {statusMessage === 1 ? (
                                    <>
                                        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span className="sr-only">Check icon</span>
                                        </div>
                                        <div className="ml-3 text-sm font-normal">Message sent!</div>
                                    </>
                                ) : <></>}
                                {statusMessage === 2 ? (
                                    <>
                                        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                            <span className="sr-only">Error icon</span>
                                        </div>
                                        <div className="ml-3 text-sm font-normal">Something went wrong. Please try again.</div>
                                    </>
                                ) : <></>}
                            </div>
                        ) : <></>}
                        <div className="modal__content relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-blue-600">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>

                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Contact us</h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">Contact us with any enquiry about our products or business opportunities. For bug reports and feature requests, raise a ticket <a href="https://github.com/ArcheeApp/support/issues/new/choose" className="text-blue-600">here</a>.</p>
                                        </div>
                                        <div>
                                            <label htmlFor="contact" className="mt-2 block text-sm font-medium leading-6 text-gray-900">Where to contact you</label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input type="text" id="contact" maxLength={1000} onChange={(e) => setContact(e.target.value)} value={contact} name="contact" className="block w-full rounded-md py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="e.g. Email, Discord, LinkedIn" />
                                                <div className="text-gray-600 text-sm mt-1 pb-1 float-right">{contact.length}/1000</div>
                                                {contactError ? <div className="text-sm pb-2 mt-1 text-red-600">Contact cannot be empty.</div> : <></>}
                                            </div>
                                            <label htmlFor="reason" className="mt-2 block text-sm font-medium leading-6 text-gray-900">Reason for contact</label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <textarea id="reason" name="reason" maxLength={1000} onChange={(e) => setReason(e.target.value)} value={reason} rows={4} className="block w-full rounded-md py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="e.g. JIRA Server integration, business, etc." />
                                                <div className="text-gray-600 text-sm mt-1 pb-1 float-right">{reason.length}/1000</div>
                                                {reasonError ? <div className="text-sm pb-2 mt-1 text-red-600">Reason cannot be empty.</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button" className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto" onClick={() => handleSend()} disabled={disabledSend}>Send Message</button>
                                <button type="button" onClick={() => closeModal("contactUsModal")} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
                    <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c7d2fe] to-[#2563eb] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
                <div className="mx-auto max-w-2xl">
                    <div className="mb-8 flex flex-col justify-center">
                        <h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl">Meet the team</h1>
                    </div>
                    <p className="text-lg text-gray-600">Our team brings together a diverse range of skills and experience, united by a passion for delivering innovative and effective solutions to help our clients achieve their goals.</p>
                    <div className="flex flex-wrap justify-center mt-8">
                        {Object.keys(Team).map((teamKey) => {
                            return <Member data={Team[teamKey]} key={teamKey} />
                        })}
                    </div>
                    <div className="my-8 sm:my-16 text-center">
                        <button type="button" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={() => toggleModal("contactUsModal")}>Contact us</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default OurTeam;