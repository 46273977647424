import { useState, useEffect } from 'react';
import { useUserContext } from '../../Contexts/User';
import { useWebSocketContext } from '../../Contexts/WebSocket';
import { ReactComponent as CorgiIcon } from "./corgi.svg";
import { ReactComponent as UserIcon } from "./user-solid.svg";
import { useNavigate } from "react-router-dom";
import { getRoomName } from '../../Common/Utils';

interface NavbarProps {
    selected?: number;
    right?: boolean;
    route?: string;
}

interface NavItem {
    name: string;
    path: string;
    position: string;
    link?: boolean;
}


const Navbar = ({ selected, right, route }: NavbarProps) => {

    const { username, changeUsername, UUID, changeSpectator, spectator } = useUserContext();
    const { sendWSMessage } = useWebSocketContext();

    const [isUserDropdownShown, setIsUserDropdownShown] = useState(false);
    const [isMobileUserDropdownShown, setMobileUserDropdownShown] = useState(false);

    const navigate = useNavigate();
    const toggleModal = (modalClass: string) => {
        let modal = document.getElementById(modalClass);
        if (modal !== undefined && modal !== null) {
            modal.style.display = "block";
            modal.classList.add("modal-open");
        }
    };

    const closeModal = (modalClass: string) => {
        let modal = document.getElementById(modalClass);
        if (modal !== undefined && modal !== null) {
            modal.style.display = "none";
        }
    };

    const toggleSpectate = () => {
      const newSpectatorState = spectator ? false : true;
      localStorage.setItem("spectator", String(newSpectatorState));
      //console.log(newSpectatorState)
      //console.log("Toggle", !spectator);
      sendWSMessage(JSON.stringify({ message: newSpectatorState, type: "spectate", uuid: UUID, room: getRoomName() }));
      changeSpectator(newSpectatorState);
    };

    // onClick event, update the localStorage with the newly chosen name, then broadcast to all users
    const updateUsername = () => {
        if (localStorage.getItem("name") !== username) {
            localStorage.setItem("name", username);
        }
        sendWSMessage(JSON.stringify({ message: username, type: "username", uuid: UUID, room: getRoomName() }));
        closeModal("usernameModal");
    };

    const handleUserClick = () => {
        setIsUserDropdownShown(!isUserDropdownShown);
    }

    const toggleMobileUserDropdown = () => {
        setMobileUserDropdownShown(!isMobileUserDropdownShown);
    }

    useEffect(() => {
        // Handler to call on window resize
        const handleClickOutside = (event: Event) => {
            const userMenuContainer = document.getElementById('userMenuContainer') as HTMLElement;
            const dropdownMenu = document.getElementById('userMenu') as HTMLElement;
            if (userMenuContainer && dropdownMenu && event.target) {
                const insideClick = !userMenuContainer.contains(event.target as HTMLElement);
                if (insideClick) {
                    setIsUserDropdownShown(false);
                }
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        // Remove event listener on cleanup
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const leftNavList: NavItem[] = [
        {
            name: 'Planning Poker',
            path: '/poker',
            position: 'left',
        }
    ]

    const rightNavList: NavItem[] = [
        {
            name: 'Wiki',
            path: 'https://github.com/ArcheeApp/support/wiki',
            position: 'right',
            link: true,
        },
        {
            name: 'Blog',
            path: '/blogs',
            position: 'right',
        },
        {
            name: 'Our Team',
            path: '/our-team',
            position: 'right',
        }
    ]



    const defaultClass = 'mx-1 text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium';
    const selectedClass = 'mx-1 bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium'

    const defaultMobileClass = 'w-full text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium';
    const selectedMobileClass = 'w-full bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium';

    return (
        <nav className="bg-gray-800" style={{ zIndex: 10000 }}>
            {/* <div>
                {size.width}px / {size.height}px
            </div> */}
            <div id="usernameModal" style={{ display: localStorage.getItem("name") !== null || route !== 'poker' ? "none" : "block" }} className={username.length > 0 ? "" : "modal-open"}>
                <div id="usernameModalContainer" className="modal">
                    <div className="modal__content relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6 text-blue-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                    </svg>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Set Nickname</h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">{localStorage.getItem("name") !== null ? "Updat" : "Sett"}ing your nickname will reflect for all users.</p>
                                    </div>
                                    <div>
                                        <label htmlFor="username" className="mt-2 block text-sm font-medium leading-6 text-gray-900">New Nickname</label>
                                        <div className="relative mt-2 rounded-md shadow-sm">
                                            <input onChange={(e) => changeUsername(e.target.value)} value={username} type="text" id="username" name="username" className="block w-full rounded-md py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nickname" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button type="button" onClick={() => updateUsername()} className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Change Nickname</button>
                            <button type="button" onClick={() => closeModal("usernameModal")} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        <button type="button" onClick={() => toggleMobileUserDropdown()} className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            {!isMobileUserDropdownShown ? (
                                <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
                                </svg>
                            ) : (
                                <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            )}
                        </button>
                    </div>
                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <button type="button" onClick={() => navigate('/')} className="flex flex-shrink-0 items-center">
                            <CorgiIcon className="block h-8 w-auto lg:hidden" />
                            <CorgiIcon className="hidden h-8 w-auto lg:block" />
                        </button>
                        <div className="hidden sm:ml-6 sm:block flex-1">
                            <div className="flex space-x-4 w-full justify-between">
                                <div className='text-center'>
                                    {leftNavList.map((navItem: NavItem, i: number) => {
                                        if (navItem.link) {
                                            return <a href={navItem.path} key={`left_${i}`} className={(selected === (i + 1) && !right) ? selectedClass : defaultClass}>{navItem.name}</a>
                                        }
                                        return <button type='button' key={`left_${i}`} onClick={() => navigate(navItem.path)} className={(selected === (i + 1) && !right) ? selectedClass : defaultClass}>{navItem.name}</button>
                                    })}
                                </div>
                                <div className='text-center'>
                                    {rightNavList.filter((navItem: NavItem) => navItem.position === 'right').map((navItem: NavItem, i: number) => {
                                        if (navItem.link) {
                                            return <a href={navItem.path} key={`right_${i}`} className={(selected === (i + 1) && right) ? selectedClass : defaultClass}>{navItem.name}</a>
                                        }
                                        return <button type='button' key={`right_${i}`} onClick={() => navigate(navItem.path)} className={(selected === (i + 1) && right) ? selectedClass : defaultClass}>{navItem.name}</button>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <div className="relative ml-3" onClick={handleUserClick} id="userMenuContainer">
                            <div>
                                <button type="button" className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" id="user-menu-button" x-ref="button" aria-expanded="false" aria-haspopup="true">
                                    <span className="sr-only">Open user menu</span>
                                    <div className='h-8 w-8 rounded-full' style={{ backgroundColor: '#60a5fa' }}>
                                        <UserIcon className="icon icon__user" />
                                    </div>
                                </button>
                            </div>

                            <div className={`absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${!isUserDropdownShown ? 'hidden' : ''}`} role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" id="userMenu">
                                {route === 'poker' ? (
                                    <div className="flex px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="user-menu-item-0">
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input type="checkbox" value="" defaultChecked={spectator} onClick={() => toggleSpectate()} className="sr-only peer" />
                                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                            <span className="ml-3 text-sm font-medium text-gray-700">Spectator</span>
                                        </label>
                                    </div>
                                ) : <></>}
                                <button type='button' className="block px-4 py-2 text-sm font-medium text-gray-700 w-full text-left" role="menuitem" tabIndex={-1} id="user-menu-item-1" onClick={() => toggleModal("usernameModal")}>
                                    {localStorage.getItem("name") !== null ? 'Change' : 'Set'} Nickname
                                </button>
                                {route === 'poker' ? (
                                    <button type='button' className="block px-4 py-2 text-sm font-medium text-gray-700 w-full text-left" role="menuitem" tabIndex={-1} id="user-menu-item-2" onClick={() => toggleModal("jiraModal")}>
                                        Change JIRA Issue
                                    </button>
                                ) : <></>}
                            </div >
                        </div >
                    </div >
                </div >
            </div >

            {isMobileUserDropdownShown ? (
                <div className="sm:hidden" id="mobile-menu">
                    <div className="space-y-1 px-2 pb-3 pt-2 text-center">
                        {leftNavList.map((navItem: NavItem, i: number) => {
                            if (navItem.link) {
                                return <a href={navItem.path} key={`mobile_left_${i}`} className={(selected === (i + 1) && !right) ? selectedMobileClass : defaultMobileClass}>{navItem.name}</a>
                            }
                            return <button type='button' key={`mobile_left_${i}`} onClick={() => navigate(navItem.path)} className={(selected === (i + 1) && !right) ? selectedMobileClass : defaultMobileClass}>{navItem.name}</button>
                        })}
                        {rightNavList.map((navItem: NavItem, i: number) => {
                            if (navItem.link) {
                                return <a href={navItem.path} key={`mobile_right_${i}`} className={(selected === (i + 1) && right) ? selectedMobileClass : defaultMobileClass}>{navItem.name}</a>
                            }
                            return <button type='button' key={`mobile_right_${i}`} onClick={() => navigate(navItem.path)} className={(selected === (i + 1) && right) ? selectedMobileClass : defaultMobileClass}>{navItem.name}</button>
                        })}
                    </div>
                </div>
            ) : <></>}
        </nav>
    );
};

export default Navbar;
