import { users } from "../../../Common/Interfaces";
import { useState } from "react";
import IssueType from "./IssueType";
import PriorityType from "./PriorityType";
import "./Jira.scss";
import { useWebSocketContext } from "../../../Contexts/WebSocket";
import { ReactComponent as Visit } from "./arrow-up-right-from-square-solid.svg";

interface IssueProps {
  mobile?: boolean;
}

const Issue = ({ mobile }: IssueProps) => {
  const { issue, filters } = useWebSocketContext();
  return (
    <div className={`ticket ${mobile ? 'ticket-mobile' : ''}`}>
      <div className="ticket-header">
        <div style={{ flexGrow: 1, paddingRight: "10px" }}>{issue.fields.summary}</div>
        <div className="icon__user">
          <a style={{ textDecoration: "none" }} href={`https://jira.tools.telstra.com/browse/${issue.key}`} target="_blank" rel="noopener noreferrer">
            <Visit className="icon" />
          </a>
        </div>
      </div>
      <div className="ticket-footer">
        <div className="ticket-footer-left">
          <div style={{ marginRight: "5px" }}>
            <strong style={{ color: "rgb(72, 84, 93)" }}>{issue.key}</strong>
          </div>
          <IssueType issue={issue} />
          <div style={{ marginRight: "5px" }}></div>
          <PriorityType issue={issue} />
        </div>
        <div className="ticket-footer-right">
          <div style={{ fontWeight: "bold" }}>Details</div>
        </div>
      </div>
      <div className="ticket-footer">
        <div className="ticket-footer-left">
          <div style={{ marginRight: "5px" }}>
            <strong style={{ color: "rgb(72, 84, 93)" }}>Creator</strong>
          </div>
        </div>
        <div className="ticket-footer-right">
          <div>{issue.fields.creator.displayName}</div>
        </div>
      </div>
      <div className="ticket-footer">
        <div className="ticket-footer-left">
          <div style={{ marginRight: "5px" }}>
            <strong style={{ color: "rgb(72, 84, 93)" }}>Assignee</strong>
          </div>
        </div>
        <div className="ticket-footer-right">
          <div>{issue.fields.assignee !== undefined && issue.fields.assignee !== null ? issue.fields.assignee.displayName : "Not Assigned"}</div>
        </div>
      </div>
      {/* {filters.map((k: any, i: number) => {
        const CUSTOM_FIELD = k[0];

        return (
          <div key={k} className="ticket-footer">
            <div className="ticket-footer-left">
              <div style={{ marginRight: "5px" }}>
                <strong style={{ color: "rgb(72, 84, 93)" }}>{k[1]}</strong>
              </div>
            </div>
            <div className="ticket-footer-right">
              <div>{typeof issue.fields[CUSTOM_FIELD] === "string" || typeof issue.fields[CUSTOM_FIELD] === "number" ? issue.fields[CUSTOM_FIELD] : issue.fields[CUSTOM_FIELD] === null ? "No value set" : "N/A"}</div>
            </div>
          </div>
        );
      })} */}
      {/* <div style={{ fontWeight: "bold", marginTop: "1%" }}>Creator</div>
      <div>{issue.fields.creator.displayName}</div>
      <div style={{ fontWeight: "bold", marginTop: "1%" }}>Assignee</div>
      <div>{issue.fields.assignee !== undefined && issue.fields.assignee !== null ? issue.fields.assignee.displayName : "Not Assigned"}</div> */}
      {/* <div>
        <div style={{ fontWeight: "bold", marginTop: "1%" }}>Ticket ID</div>
        <div>
          <a href={`https://jira.tools.telstra.com/browse/${issue.key}`}>{issue.key}</a>
        </div>
        <div style={{ fontWeight: "bold", marginTop: "1%" }}>Title</div>
        <div>{issue.fields.summary}</div>
        <div style={{ fontWeight: "bold", marginTop: "1%" }}>Creator</div>
        <div>{issue.fields.creator.displayName}</div>
        <div style={{ fontWeight: "bold", marginTop: "1%" }}>Assignee</div>
        <div>{issue.fields.assignee !== undefined && issue.fields.assignee !== null ? issue.fields.assignee.displayName : "Not Assigned"}</div>
        <div style={{ fontWeight: "bold", marginTop: "1%" }}>Description</div>
        {issue.fields.description !== null ? <div>{issue.fields.description.content[0].content[0].text}</div> : <div>N/A</div>}
        <div style={{ fontWeight: "bold", marginTop: "1%" }}>Story Points</div>
        <div>{issue.fields.customfield_10026 !== null ? issue.fields.customfield_10026 : "N/A"}</div>
        {issue.fields.comment.comments.length > 0 ? (
          <div>
            <div style={{ fontWeight: "bold", marginTop: "1%" }}>Comments ({issue.fields.comment.comments.length})</div>
            {issue.fields.comment.comments.map((k: any, i: number) => {
              return (
                <div key={k}>
                  {k.author.displayName}: {k.body.content[0].content[0].text}
                </div>
              );
            })}
          </div>
        ) : null}
      </div> */}
    </div>
  );

}

export default Issue;