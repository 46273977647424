import { useState } from "react";
import Result from "../Result/Result";
import { useUserContext } from "../../../Contexts/User";
import { useWebSocketContext } from "../../../Contexts/WebSocket";
import { getRoomName } from "../../../Common/Utils";
import { useWindowSize, isDesktop } from "../../../Common/Utils";

import "./Choose.scss";

const Choose = () => {
  const { UUID, changeChoice, spectator } = useUserContext();
  const { sendWSMessage, reveal } = useWebSocketContext();

  const size = useWindowSize();

  const [values] = useState([0.5, 1, 2, 3, 5, 8, 13, 21]);

  // onClick event
  const makeChoice = (val: any) => {
    if (!spectator) {
      changeChoice(val);
      sendWSMessage(JSON.stringify({ message: val, type: "choice", uuid: UUID, room: getRoomName() }));
    }
  };

  return (
    <>
      {isDesktop(size) ? <div className="px-8 sticky top-[77vh]">
        {reveal ?
          <div className="py-4 mb-18 rounded-xl" style={{ width: "100%", backgroundColor: '#f5f5f5', marginTop: 'auto' }}>
            <Result size={size} />
          </div>
          :
          <div className="w-full px-8 sticky top-[82vh]" style={{ display: "flex", justifyContent: "center", marginTop: 'auto' }}>
            <div className="bg-gray-200 w-full h-full p-12 text-center rounded-xl">
              {values.map((k: number, i: number) => {
                return <ChooseBtn key={k} value={k} makeChoice={makeChoice} />
              })}
            </div>
          </div>
        }
      </div> : <div className="my-4">
        {reveal ?
          <div className="py-4 mx-auto rounded-2xl" style={{ width: "80%", backgroundColor: '#f5f5f5' }}>
            <Result size={size} />
          </div>
          :
          <div className="flex text-center w-full flex-wrap justify-center p-6 bg-gray-200 rounded-xl mx-auto max-w-2xl" style={{ width: '80%', minWidth: '80%' }}>
            {values.map((k: number, i: number) => {
              return <ChooseBtn key={k} value={k} makeChoice={makeChoice} />
            })}
          </div>
        }
      </div>}

    </>
  );
};

const ChooseBtn = (props: any) => {
  const { spectator } = useUserContext();
  const { value, makeChoice } = props;

  function handleClick(event: any) {
    const buttons = document.getElementsByClassName("btn__choose");
    Array.prototype.forEach.call(buttons, function (button) {
      button.classList.remove("btn__active");
    });
    (event.target as Element).classList.toggle("btn__active");
    return !spectator ? makeChoice(value) : null
  }

  return (
    <button value={value} onClick={(e) => handleClick(e)} className="m-1 px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 btn btn__choose">
      {value}
    </button>
  );
};

export default Choose;
