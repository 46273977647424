interface FooterProps {
    route?: string;
}

const Footer = ({ route }: FooterProps) => {

    return (
        <footer className="sticky top-[100vh] h-18 bg-gray-800">
            <div className={`my-4 ${route === 'poker' ? 'p-5' : 'p-1'} text-center leading-6 text-gray-300 text-sm font-medium`}>
                <div>© 2023 Archee. All rights reserved.</div>
                <div className="mt-2 flex items-center justify-center space-x-4 text-sm font-semibold leading-6 text-slate-700 text-gray-300">
                    <a href="https://github.com/ArcheeApp/support/wiki/Acknowledgements" className="text-gray-300">Acknowledgements</a>
                    <div className="h-4 w-px bg-gray-300"></div>
                    <a href="https://github.com/ArcheeApp/support/issues/new/choose" className="text-gray-300">Report a Bug</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
