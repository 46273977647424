import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Team from "../../../Common/Team";
import { setWindowTitle } from "../../../Common/Utils";

const BlogPage = () => {

    const [blogData, setBlogData] = useState<any>(null)

    const navigate = useNavigate();

    const parsedDate = () => {
        const date = new Date(blogData.overview.date);
        return `${date.toLocaleString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })}, ${date.getFullYear()}`;
    }

    useEffect(() => {
        let isActive = true;
        const path = window.location.pathname;
        const blogId = path.substring(7);
        const fetchBlog = async () => {
            try {
                const data = await fetch(`/blogs/data/posts/${blogId}`);
                const json = await data.json();
                if (isActive) {
                    if (json.disabled) {
                        navigate(`/blogs`);
                    }
                    setBlogData(json);
                }
            } catch {
                if (isActive) {
                    navigate(`/blogs`);
                }
            }
        }
        fetchBlog();
        return () => { isActive = false };
    }, []); //eslint-disable-line

    useEffect(() => {
        if (blogData) {
            if (blogData.overview.title) {
                setWindowTitle(blogData.overview.title);
            }
        }
    }, [blogData]); //eslint-disable-line

    setWindowTitle('');

    return blogData ? (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <Navbar selected={2} right={true} />
            <button type="button" onClick={() => navigate(`/blogs`)} className="max-w-8xl mr-auto" style={{ zIndex: 10000 }}>
                <div className="flex px-4 pt-8 pb-10 lg:px-8">
                    <div className="group flex font-semibold text-sm leading-6 text-slate-700 hover:text-slate-900 dark:text-slate-200 dark:hover:text-white">
                        <svg viewBox="0 -9 3 24" className="overflow-visible mr-3 text-slate-700 w-auto h-6 group-hover:text-slate-500"><path d="M3 0L0 3L3 6" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                        <span className="text-slate-700 group-hover:text-slate-500">Go back</span>
                    </div>
                </div>
            </button>
            <div className="relative isolate px-6 lg:px-8">
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
                    <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c7d2fe] to-[#2563eb] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
                <div className="mx-auto max-w-2xl">
                    <div className="text-sm leading-6">
                        <dl>
                            <dt className="sr-only">Date</dt>
                            <dd className="text-gray-600">
                                <time dateTime={blogData.overview.date}>{parsedDate()}</time>
                            </dd>
                        </dl>
                    </div>
                    <h1 className="text-2xl font-bold tracking-tight text-slate-900 md:text-3xl ">{blogData.overview.title}</h1>
                    <div className="mt-auto flex pt-3">
                        <div className="w-10 h-10 overflow-hidden rounded-full">
                            <img style={{ objectFit: "cover", minHeight: "100%", minWidth: "100%" }} src={Team[blogData.overview.author].image} alt={blogData.overview.author}></img>
                        </div>
                        <div className="ml-4">
                            <div className="font-semibold">{Team[blogData.overview.author].name}</div>
                            <div className="text-sm text-gray-600">{Team[blogData.overview.author].role}</div>
                        </div>
                    </div>
                    <div className="my-8">
                        <div className="relative not-prose my-[2em] first:mt-0 last:mb-0 rounded-2xl overflow-hidden shadow-xl">
                            <img src={blogData.overview.image} alt={blogData.overview.title} decoding="async"></img>
                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-slate-900/10 dark:ring-white/10"></div>
                        </div>
                    </div>
                    <p className="mb-8" dangerouslySetInnerHTML={{ __html: blogData.content }}></p>
                </div>
            </div>
            <Footer />
        </div>
    ) : <></>;
};

export default BlogPage;