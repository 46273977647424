import { useEffect, useState } from "react";

import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Preview from "../Preview/Preview";
import { setWindowTitle } from "../../../Common/Utils";

const BlogLanding = () => {

    setWindowTitle('Blogs');

    const [blogData, setBlogData] = useState<any[]>([])

    useEffect(() => {
        let isActive = true;
        const fetchBlogs = async () => {
            const data = await fetch('/blogs/data/overview');
            const json = await data.json();
            if (isActive) {
                setBlogData(json);
            }
        }
        fetchBlogs();
        return () => { isActive = false };
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <Navbar selected={2} right={true} />
            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
                    <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c7d2fe] to-[#2563eb] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
                <div className="mx-auto max-w-2xl">
                    <div className="mb-8 flex flex-col justify-center">
                        <h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl">Latest Updates</h1>
                    </div>
                    <p className="text-lg text-gray-600">All the latest news about Archee, straight from the team.</p>
                    <div className="text-center">
                        {blogData && blogData.map(blog => {
                            return <Preview key={blog.id} data={blog} />
                        })}
                        {/* <div>
                            <button type="button" className="bg-gray-100 hover:bg-gray-200 text-gray-600 font-semibold hover:text-gray-600 py-2 px-4 border border-gray-400 rounded mx-1">Previous</button>
                            <button type="button" className="bg-gray-100 hover:bg-gray-200 text-gray-600 font-semibold hover:text-gray-600 py-2 px-4 border border-gray-400 rounded mx-1 my-8">Next</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BlogLanding;