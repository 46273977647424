import { useEffect, useState } from "react";
import { useUserContext } from "../../../Contexts/User";
import Navbar from "../../Navbar/Navbar";
import { useWebSocketContext } from "../../../Contexts/WebSocket";
import { getRoomName } from "../../../Common/Utils";

import "./Slider.scss";
import "./Dropdown.scss";
import "./Modal.scss";

const Username = (props: any) => {
  const { username, changeUsername, changeUUID, changeSpectator, changeRoom } = useUserContext();
  const { sendWSMessage, users } = useWebSocketContext();
  const [copied, setCopied] = useState(false);

  const [stateIssue, setIssue] = useState("");

  // If a user page reloaded or disconnected, state is restored
  useEffect(() => {
    const savedRoom = localStorage.getItem("room");

    if (savedRoom === null) {
      //console.log("room null");
    }

    const name = localStorage.getItem("name");
    const id = localStorage.getItem("pokerUUID");
    const isSpectator = localStorage.getItem("spectator");
    //console.log(isSpectator);
    if (name !== null && name?.length > 0) {
      changeUsername(name);
    }
    if (name !== null && name?.length > 0 && id !== null && savedRoom !== null) {
      //console.log("username: first condition check");
      changeUsername(name);
      changeUUID(id);
      changeSpectator(isSpectator === "true" ? true : false);
      changeRoom(savedRoom);
      sendWSMessage(JSON.stringify({ message: name, type: "username", uuid: id, room: getRoomName(), debug: "reloading page" }));
    } else {
      //console.log("username: second condition");
      const generatedUUID = crypto.randomUUID();
      localStorage.setItem("pokerUUID", generatedUUID);
      localStorage.setItem("spectator", "false");
      changeUUID(generatedUUID);
      sendWSMessage(JSON.stringify({ message: username, type: "username", uuid: generatedUUID, room: getRoomName(), debug: "no details" }));
    }
  }, []); //eslint-disable-line

  const closeModal = (modalClass: string) => {
    let modal = document.getElementById(modalClass);
    if (modal !== undefined && modal !== null) {
      modal.style.display = "none";
    }
  };

  const copyInvitationLink = () => {
    navigator.clipboard.writeText(window.location.href);
    //console.log("Invitation Copied!");
    setCopied(true);
  };

  const getIssue = (e: any) => {
    sendWSMessage(JSON.stringify({ message: stateIssue, type: "issue", issue: stateIssue, room: getRoomName() }));
  };

  const renderHeaderCopy = () => {
    if (users.users.length > 1) {
      return (
        <button type="button" className="btn" style={{ backgroundColor: copied ? "green" : "", minHeight: "36px", padding: "12px 15px", fontSize: "16px" }} onClick={() => copyInvitationLink()}>
          {!copied ? "Copy Invitation Link" : "Copied!"}
        </button>
      );
    }
  };

  return (
    <>
      <Navbar selected={1} route={'poker'} />
      <div style={{ width: "100%", backgroundColor: "white", display: "flex" }}>
        <div id="jiraModal" style={{ display: "none" }}>
          <div id="jiraModalContainer" className="modal">
            <div className="modal__content relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6 text-blue-600">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Select JIRA Issue</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">If JIRA is configured, type the Issue ID to fetch it for users to see.</p>
                    </div>
                    <div>
                      <label htmlFor="issueInput" className="mt-2 block text-sm font-medium leading-6 text-gray-900">JIRA Issue ID</label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input value={stateIssue} onChange={(e) => setIssue(e.target.value)} id="issueInput" className="block w-full rounded-md py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="ABCDE-12345" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button" onClick={(e) => getIssue(e)} className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Retrieve Issue</button>
                <button type="button" onClick={() => closeModal("jiraModal")} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Username;
