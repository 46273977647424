import { useUserContext } from "../../../Contexts/User";
import { useWebSocketContext } from "../../../Contexts/WebSocket";
import { getRoomName } from "../../../Common/Utils";

const Flip = (props: any) => {
  const { UUID, room } = useUserContext();
  const { sendWSMessage } = useWebSocketContext();

  const revealCards = () => {
    sendWSMessage(JSON.stringify({ message: true, type: "flip", uuid: UUID, room: getRoomName() }));
  };

  return (
    <div>
      <button onClick={revealCards} className="mx-1 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-base px-6 py-3.5 text-center mb-2">
        Reveal Cards
      </button>
    </div>
  );
};

export default Flip;
