import { users } from "../../../Common/Interfaces";
import { useState, useEffect } from "react";
import Flip from "../Flip/Flip";
import Reset from "../Reset/Reset";
import Issue from "../Issue/Issue";
import "./Table.scss";
import "../Issue/Jira.scss";
import { ReactComponent as Eye } from "./eye-solid.svg";
import { useWebSocketContext } from "../../../Contexts/WebSocket";
import { ReactComponent as UserIcon } from "../../Navbar/user-solid.svg";

const Table = () => {
  const { users, reveal, issue } = useWebSocketContext();
  const [copied, setCopied] = useState(false);

  const generateCardClass = (user: any) => {
    if (user.spectate) {
      return "card__spectator";
    };
    if (reveal) {
      return "card__visible";
    };
    if (user.visible === -1) {
      return "card__no-choice";
    };
    return "card";
  }

  const createCard = (userArray: users[], vertical: boolean = false) => {
    return userArray.map((user: any, index: number) => (
      <div key={`user_${index}`} id={`user_${index}`} className="card__individual">
        <div>
          {/* <div className={user.spectate ? "card__spectator" : reveal ? "card__visible" : "card"} style={{ margin: vertical ? "0 auto" : "0 auto" }}> */}
          <div className={`${generateCardClass(user)} rounded-lg`} style={{ margin: vertical ? "0 auto" : "0 auto" }}>
            {reveal && !user.spectate ? <div><strong>{user.choice}</strong></div> : ""}
            {user.spectate ? <div><Eye className="eye" /></div> : ""}
          </div>
          <div>
            <div className="text-lg mt-2 font-semibold">{user.name}</div>
          </div>
        </div>
      </div>
    ));
  };

  const getUserChunk = (startIndex: number, endIndex: number) => {
    if (users.users !== undefined && users.users.length > 0) {
      const filteredUsers = users.users.filter((user: any) => !user.spectate)
      const userArray = filteredUsers.slice(startIndex, endIndex);
      return createCard(userArray);
    }
  }

  const copyInvitationLink = () => {
    navigator.clipboard.writeText(window.location.href);
    //console.log("Invitation Copied!");
    setCopied(true);
  };

  const handleSpectatorToggle = () => {
    const spectatorMenu = document.getElementById('spectatorMenu') as HTMLElement;
    let spectatorList: any[] = [];
    if (users.users !== undefined && users.users.length > 0) {
      spectatorList = users.users.filter((user: any) => user.spectate);
    }
    if (spectatorMenu && spectatorList.length > 0) {
      spectatorMenu.classList.toggle('hidden');
    }
  }

  const createAdditionalUsersChunk = () => {
    if (users.users !== undefined && users.users.length > 0) {
      const filteredUsers = users.users.filter((user: any) => !user.spectate)
      //slice should be 10
      const userArray = filteredUsers.slice(10);
      return <>
        {userArray.length ? (
          <div className="my-8">
            <ul className="w-full divide-y divide-gray-200 p-8 shadow-md bg-gray-50 rounded-xl">
              {
                userArray.map((user: any, index: number) => {
                  return <li className="py-3 last:pb-0 first:pt-0" key={`user_${index}`}>
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <div className='h-8 w-8 rounded-full' style={{ backgroundColor: '#60a5fa' }}>
                          <UserIcon className="icon icon__user" style={{ paddingTop: '7px' }} />
                        </div>
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {user.name}
                        </p>
                      </div>
                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        {reveal ? (
                          <div className="w-12 h-16 bg-blue-300 rounded-md">
                            <div className="text-black text-2xl text-center m-auto pt-4">{user.choice}</div>
                          </div>
                        ) : (
                          <div className={`w-12 h-16 ${user.choice ? 'bg-blue-900' : 'bg-gray-300'} rounded-md`}></div>
                        )}

                      </div>
                    </div>
                  </li>
                })
              }
            </ul>
          </div>
        ) : (
          <></>
        )}
      </>
    }
  }

  const renderSpectators = () => {
    if (users.users !== undefined && users.users.length > 0) {
      const spectators = users.users.filter((user: any) => user.spectate)
      return spectators.length > 0 ? <div className="spectatorContainer" id="spectatorContainer">
        <div className="relative inline-block text-left">
          <div>
            <button type="button" id="toggleSpectatorMenu" className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" aria-expanded="true" aria-haspopup="true" onClick={handleSpectatorToggle}>
              Spectators
              <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <div id="spectatorMenu" className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
            <div className="py-1" role="none">
              {spectators.map((user: any, index: number) => (
                <div key={'index' + index} className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" id="menu-item-0">
                  {user.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> : <></>;
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <div className="w-full flex justify-center">
        <div className="table__grid" style={{ marginTop: "100px" }}>
          <div id="topLeft"></div>
          <div id="topMiddle" className="card__container">
            {getUserChunk(0, 4)}
          </div>
          <div id="topRight"></div>
          <div id="midLeft" className="card__container card__container__vertical">
            {getUserChunk(4, 5)}
          </div>
          <div id="table" className="table">
            {issue.fields !== undefined ?
              <div style={{ display: "flex", height: "100%", flexDirection: "column", textAlign: "center", justifyContent: "center" }}>
                <Issue />
              </div> : <div style={{ display: "flex", height: "100%", flexDirection: "column", textAlign: "center", justifyContent: "center" }}></div>}
            {
              users.users !== undefined && users.users.length <= 1
                ? ""
                : <div className="table__actions">
                  <Flip />
                  <Reset />
                </div>
            }

          </div>
          <div id="midRight" className="card__container card__container__vertical">
            {getUserChunk(5, 6)}
          </div>
          <div id="bottomLeft"></div>
          <div id="bottomMiddle" className="card__container">
            {
              users.users !== undefined && users.users.length <= 1
                ?
                <div className="text-center">
                  <div className="my-3 text-center text-base leading-6 text-slate-500"><h4 style={{ marginTop: 0 }}>Feeling lonely? Invite someone!</h4></div>
                  <button type="button" className="bg-blue-800 text-white rounded-md px-6 py-3.5 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800" style={{ margin: '0 auto', backgroundColor: copied ? "green" : "" }} onClick={() => copyInvitationLink()}>{!copied ? "Copy Invitation Link" : "Copied!"}</button>
                </div>
                : ""
            }
            {getUserChunk(6, 10)}
          </div>
          <div id="bottomRight"></div>
          {renderSpectators()}
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-1/2">
          {createAdditionalUsersChunk()}
        </div>
      </div>
    </div>
  );
};

export default Table;
