import { useUserContext } from "../../../Contexts/User";
import { useWebSocketContext } from "../../../Contexts/WebSocket";
import { getRoomName } from "../../../Common/Utils";

const Reset = () => {
  const { UUID, room } = useUserContext();
  const { sendWSMessage } = useWebSocketContext();

  // onClick event
  const resetGame = () => {
    sendWSMessage(JSON.stringify({ message: true, type: "reset", uuid: UUID, room: getRoomName() }));
  };

  return (
    <div>
      <button onClick={resetGame} className="mx-1 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-base px-6 py-3.5 text-center mb-2">
        New Game
      </button>
    </div>
  );
};

export default Reset;
