import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Corgi from "./Corgi/Corgi";
import { setWindowTitle } from "../../Common/Utils";

import './Homepage.scss';

const Homepage = () => {

    setWindowTitle('Home');

    const navigate = useNavigate();

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", backgroundColor: "#f5f5f5" }}>
            <Navbar />
            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
                    <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c7d2fe] to-[#2563eb] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
                <div className="mx-auto max-w-2xl py-32 sm:pyblogs/1-48 lg:py-56">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                        <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                            Archee is currently in Beta. <button type="button" onClick={() => navigate("/blogs/1")} className="font-semibold text-indigo-600"><span className="absolute inset-0" aria-hidden="true"></span>Read more <span aria-hidden="true">&rarr;</span></button>
                        </div>
                    </div>
                    <div className="text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Archee</h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">Collaborate and conquer your sprints with our suite of Agile tools.</p>
                    </div>
                </div>
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                    <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#c7d2fe] to-[#2563eb] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
                <Corgi />
            </div>
            <div className="relative isolate pt-20">
                <div className="bg-gray-200 block sm:flex">
                    <div className="mx-auto max-w-2xl pt-32 pb-16 sm:py-48 lg:py-56 px-24">
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Planning Poker</h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">Unleash your team's potential by planning tasks efficiently and effectively.</p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <button type="button" onClick={() => navigate(`/poker`)} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" id="poker-get-started">Get started</button>
                            </div>
                        </div>
                    </div>
                    <div className="hidden sm:block w-6/12 py-6 pl-6 h-auto">
                        <div className="rounded-l-lg bg-white overflow-hidden h-full shadow-xl">
                            <img className="homepageImage" src="https://cdn.discordapp.com/attachments/1027186342620299315/1098980413533327561/chrome_pPrPJFN5uq.png"></img>
                        </div>
                    </div>
                    <div className="sm:hidden p-6 h-auto">
                        <div className="rounded-lg bg-white overflow-hidden h-full shadow-xl">
                            <img className="homepageImage" src="https://media.discordapp.net/attachments/1027186342620299315/1099299553565483028/image.png?width=958&height=796"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative isolate">
                <div className="bg-gray-100">
                    <div className="mx-16 sm:mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Retro Board</h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">Learn from the past, improve for the future with our retro board.</p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <button type="button" className="rounded-md bg-indigo-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" disabled>Coming soon!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Homepage;