import { useNavigate } from "react-router-dom";
import Team from "../../../Common/Team";

import './Preview.scss';

interface PreviewData {
    id: number;
    date: string;
    title: string;
    image: string;
    author: string;
    tag: string;
    copy: string;
}

interface PreviewProps {
    data: PreviewData;
}

const Preview = ({ data }: PreviewProps) => {

    const navigate = useNavigate();

    const parsedDate = () => {
        const date = new Date(data.date);
        return `${date.toLocaleString('en-US', { month: 'short', day: 'numeric' })}, ${date.getFullYear()}`;
    }

    return <button type="button" onClick={() => navigate(`/blogs/${data.id}`)}>
        <article className="w-full mt-8 flex bg-white overflow-hidden rounded-xl sm:h-72 md:h-64 shadow-xl gap-8 hover:translate-x-2 transition transform motion-reduce:transition-none motion-reduce:hover:transform-none">
            <div className="rounded-l-lg w-48 md:w-64 sm:h-48 md:h-64 postImage">
                <img style={{ objectFit: "cover", minHeight: "100%", minWidth: "100%" }} src={data.image} alt={data.title}></img>
            </div>
            <div className="flex-1 py-4 text-left flex flex-col mr-8 ml-8 sm:ml-0">
                <div className="flex">
                    <time dateTime="" className="text-sm text-gray-600 mr-4">{parsedDate()}</time>
                    <div className="text-gray-800 bg-gray-200 text-xs px-2 py-1 rounded-full mx-1">{data.tag}</div>
                </div>
                <div className="mt-2 text-2xl font-bold">{data.title}</div>
                <div className="text-md my-2">{data.copy}</div>
                <div className="mt-auto border-t-2 border-gray-100 flex pt-3">
                    <div className="w-10 h-10 overflow-hidden rounded-full">
                        <img style={{ objectFit: "cover", minHeight: "100%", minWidth: "100%" }} src={Team[data.author].image} alt={data.author}></img>
                    </div>
                    <div className="ml-4">
                        <div className="font-semibold">{Team[data.author].name}</div>
                        <div className="text-sm text-gray-600">{Team[data.author].role}</div>
                    </div>
                </div>
            </div>
        </article>
    </button>
}

export default Preview;