import { createContext, useContext, useState, useMemo } from "react";
import { isEqual } from "lodash";
import useWebSocket from "react-use-websocket";
import { getRoomName } from "../Common/Utils";
// import { wsData } from "../Common/Interfaces";

const webSocketContext = createContext({
  users: { users: [] },
  changeUsers: (value: any) => { },
  reveal: false,
  changeReveal: (value: boolean) => { },
  sendWSMessage: (value: any) => { },
  issue: {} as any,
  changeIssue: (value: any) => { },
  filters: [] as any,
  changeFilters: (value: any) => { },
  filterOptions: [] as any,
  changeFilterOptions: (value: any) => { },
});

const WebSocketContextProvider = ({ children }: any) => {
  const [socketUrl] = useState(process.env.REACT_APP_WEBSOCKET_CONNECTION_URL as string);

  const [users, setUsers] = useState({ users: [] });
  const [reveal, setReveal] = useState(false);
  const [issue, setIssue] = useState({ error: "No issue found." } as any);
  const [filters, setFilters] = useState([] as any);
  const [filterOptions, setFilterOptions] = useState([] as any);

  const changeUsers = useMemo(
    () => (value: any) => {
      setUsers(value);
    },
    []
  );

  const changeReveal = useMemo(
    () => (value: boolean) => {
      setReveal(value);
    },
    []
  );

  const changeIssue = useMemo(
    () => (value: any) => {
      setIssue(value);
    },
    []
  );

  const changeFilters = useMemo(
    () => (value: any) => {
      setFilters(value);
    },
    []
  );

  const changeFilterOptions = useMemo(
    () => (value: any) => {
      setFilterOptions(value);
    },
    []
  );

  const { sendMessage } = useWebSocket(socketUrl, {
    onError(event) {
      ////console.log(event);
    },
    onOpen: () => {
      sendMessage(JSON.stringify({ message: localStorage.getItem("name"), type: "username", uuid: localStorage.getItem("pokerUUID"), room: getRoomName(), debug: "on open websocket" }));
      if (localStorage.getItem("spectator") !== null) {
        sendMessage(JSON.stringify({ message: localStorage.getItem("spectator") === "true" ? true : false, type: "spectate", uuid: localStorage.getItem("pokerUUID"), room: getRoomName(), debug: "on open spec not null" }));
      }

      sendMessage(JSON.stringify({ type: "retrieveUsers", room: getRoomName(), debug: "on open getting users" }));
    },
    onMessage: (message) => {
      const currentUsers = {
        users: JSON.parse(message.data).users,
      };

      if (!isEqual(users, currentUsers)) {
        changeUsers(currentUsers);
      }

      if (JSON.parse(message.data).reset) {
        const buttons = document.getElementsByClassName("btn__choose");
        Array.prototype.forEach.call(buttons, function (button) {
          button.classList.remove("btn__active");
        });
        changeReveal(false);
      }

      if (JSON.parse(message.data).reveal) {
        changeReveal(true);
      }

      const currentIssue = JSON.parse(message.data).currentIssue;
      if (currentIssue && !isEqual(issue, currentIssue)) {
        changeIssue(currentIssue);

        if (filterOptions.length === 0) {
          const issueData = currentIssue;
          let filtersToSet: any = [];

          if (issueData.names !== undefined) {
            Object.entries(issueData.names).map((k: any, i: number) => {
              return filtersToSet.push({ value: k[0], label: k[1] });
            });
          }

          if (!isEqual(filterOptions, filtersToSet)) {
            setFilterOptions(filtersToSet);
          }
        }
      }

      const currentFilters = JSON.parse(message.data).filters;

      if (!isEqual(filters, currentFilters)) {
        changeFilters(currentFilters);
      }
    },
  });

  const values = useMemo(() => ({ users, changeUsers, reveal, changeReveal, sendWSMessage: sendMessage, issue, changeIssue, filters, changeFilters, filterOptions, changeFilterOptions }), [users, changeUsers, reveal, changeReveal, sendMessage, issue, changeIssue, filters, changeFilters, filterOptions, changeFilterOptions]);

  return <webSocketContext.Provider value={values}>{children}</webSocketContext.Provider>;
};

const useWebSocketContext = () => {
  const context = useContext(webSocketContext);

  if (context === undefined) {
    throw new Error("useWebSocketContext was used outside of its Provider");
  }

  return context;
};

export { webSocketContext, WebSocketContextProvider, useWebSocketContext };
