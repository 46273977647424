import { users } from "../../../Common/Interfaces";
import { useState } from "react";
import Flip from "../Flip/Flip";
import Reset from "../Reset/Reset";
import "../Table/Table.scss";
import { ReactComponent as UserIcon } from "../../Navbar/user-solid.svg";
import { useWebSocketContext } from "../../../Contexts/WebSocket";

const MobileTable = () => {
    const { users, reveal } = useWebSocketContext();
    const [showSpectatorList, setShowSpectatorList] = useState(false);
    const [copied, setCopied] = useState(false);

    const copyInvitationLink = () => {
      navigator.clipboard.writeText(window.location.href);
      //console.log("Invitation Copied!");
      setCopied(true);
    };

    const toggleSpectatorMenu = () => {
        setShowSpectatorList(!showSpectatorList);
    }

    const renderSpectators = () => {
        if (users.users !== undefined && users.users.length > 0) {
            const spectators = users.users.filter((user: any) => user.spectate)
            return spectators.length > 0 ? <div className="ml-auto my-auto">
                <div className="relative inline-block text-left">
                    <div>
                        <button type="button" onClick={() => toggleSpectatorMenu()} className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" aria-expanded="true" aria-haspopup="true">
                            Spectators
                            <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    {showSpectatorList ? (
                        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                            <div className="py-1" role="none">
                                {spectators.map((user: any, index: number) => (
                                    <div key={'index' + index} className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" id="menu-item-0">
                                        {user.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : <></>}
                </div>
            </div> : <></>;
        } else {
            return <></>;
        }
    };

    const renderUsers = () => {
        if (users.users !== undefined && users.users.length > 0) {
            const voted = users.users.filter((user: any) => !user.spectate && user.choice);
            const unvoted = users.users.filter((user: any) => !user.spectate && !user.choice);
            return (voted.length + unvoted.length) > 0 ? <div className="ml-auto my-auto">
                <div className="inline-block text-left w-full">
                    <ul className="w-full divide-y divide-gray-200 p-8 shadow-md bg-gray-50 rounded-xl">
                        {unvoted ? (
                            <>
                                {
                                    unvoted.map((user: any, index: number) => {
                                        return <li className="py-3 last:pb-0 first:pt-0" key={`unvoted_${index}`}>
                                            <div className="flex items-center space-x-4">
                                                <div className="flex-shrink-0">
                                                    <div className='h-8 w-8 rounded-full' style={{ backgroundColor: '#60a5fa' }}>
                                                        <UserIcon className="icon icon__user" style={{ paddingTop: '7px' }} />
                                                    </div>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-sm font-medium text-gray-900 truncate">
                                                        {user.name}
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    <div className="w-12 h-16 bg-gray-300 rounded-md"></div>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </>
                        ) : <></>}
                        {voted ? (
                            <>
                                {
                                    voted.map((user: any, index: number) => {
                                        return <li className="py-3 last:pb-0 first:pt-0" key={`voted${index}`}>
                                            <div className="flex items-center space-x-4">
                                                <div className="flex-shrink-0">
                                                    <div className='h-8 w-8 rounded-full' style={{ backgroundColor: '#60a5fa' }}>
                                                        <UserIcon className="icon icon__user" style={{ paddingTop: '7px' }} />
                                                    </div>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-sm font-medium text-gray-900 truncate">
                                                        {user.name}
                                                    </p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    <div className={`w-12 h-16 rounded-md flex flex-col ${reveal ? 'bg-blue-300' : 'bg-blue-900'}`}>
                                                        {reveal ? <div className="text-black text-2xl text-center my-auto">{user.choice}</div> : <></>}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </>
                        ) : <></>}
                    </ul>
                </div>
            </div> : <></>;
        } else {
            return <></>;
        }
    };

    return (
        <div style={{ width: '80%' }} className="mx-auto">
            <div className="flex flex-wrap">
                {
                    users.users !== undefined && users.users.length <= 1
                        ? ""
                        : <div className="flex">
                            <Flip />
                            <Reset />
                        </div>
                }
                {renderSpectators()}
            </div>
            {renderUsers()}
            {
                users.users !== undefined && users.users.length <= 1
                    ?
                    <div className="text-center mt-8">
                        <div className="my-3 text-center text-base leading-6 text-slate-500"><h4 style={{ marginTop: 0 }}>Feeling lonely? Invite someone!</h4></div>
                        <button type="button" className="bg-blue-800 text-white rounded-md px-6 py-3.5 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800" style={{ margin: '0 auto', backgroundColor: copied ? "green" : "" }} onClick={() => copyInvitationLink()}>{!copied ? "Copy Invitation Link" : "Copied!"}</button>
                    </div>
                    : ""
            }

        </div>
    );
};

export default MobileTable;