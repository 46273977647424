import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../Contexts/User";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { setWindowTitle } from "../../../Common/Utils";

import '../Username/Modal.scss';

const PokerLanding = () => {

    setWindowTitle('Planning Poker');

    const { room, changeRoom } = useUserContext();
    const navigate = useNavigate();

    const updateUrl = (val: string) => {
        changeRoom(val);
        localStorage.setItem("room", val);
    };

    const toggleModal = (modalClass: string) => {
        let modal = document.getElementById(modalClass);
        if (modal !== undefined && modal !== null) {
            modal.style.display = "block";
            modal.classList.add("modal-open");
        }
    };

    const closeModal = (modalClass: string) => {
        let modal = document.getElementById(modalClass);
        if (modal !== undefined && modal !== null) {
            modal.style.display = "none";
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <Navbar selected={1} />
            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div id="selectRoomModal" style={{ display: "none" }}>
                    <div id="selectRoomModalContainer" className="modal">
                        <div className="modal__content relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-blue-600">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                        </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Join a Game</h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">Join an existing room, or be the first in and create a temporary room for your team!</p>
                                        </div>
                                        <div>
                                            <label htmlFor="issueInput" className="mt-2 block text-sm font-medium leading-6 text-gray-900">Room Name</label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input type="text" id="room" name="room" value={room} onChange={(e) => updateUrl(e.target.value)} className="block w-full rounded-md py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Room Name" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button" disabled={room.length === 0} onClick={() => navigate(`/poker/room/${room}`)} className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Join Room</button>
                                <button type="button" onClick={() => closeModal("selectRoomModal")} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
                    <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c7d2fe] to-[#2563eb] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                        <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                            Archee is currently in Beta. <button type="button" onClick={() => navigate("/blogs/1")} className="font-semibold text-indigo-600"><span className="absolute inset-0" aria-hidden="true"></span>Read more <span aria-hidden="true">&rarr;</span></button>
                        </div>
                    </div>
                    <div className="text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Planning Poker</h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">Elevate your planning game with us. <br />More accurate task estimates, right at your fingertips.</p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <button type="button" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={() => toggleModal("selectRoomModal")}>Start a game</button>
                            <a href="https://github.com/ArcheeApp/support/wiki/Planning-Poker" className="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PokerLanding;