import Issue from "../Issue/Issue";
import { useWebSocketContext } from "../../../Contexts/WebSocket";

const MobileIssue = () => {
    const { issue } = useWebSocketContext();

    return issue.fields !== undefined ?
        <div className="my-4">
            <Issue mobile />
        </div>

        : <></>;
}

export default MobileIssue;