import { useEffect, useState, useCallback } from "react";
import { useUserContext } from "../../../Contexts/User";
import { useWebSocketContext } from "../../../Contexts/WebSocket";
import { getRoomName } from "../../../Common/Utils";
import Select from "react-select";

import "./SelectData.scss";

const SelectData = (props: any) => {
  const { UUID } = useUserContext();
  const { sendWSMessage, filterOptions } = useWebSocketContext();
  const [selectedOption] = useState(null as any);
  const [visibility, setVisibility] = useState(false);

  const updateFilters = (filter: any) => {
    //console.log(filter);
    sendWSMessage(JSON.stringify({ message: [filter.value, filter.label], type: "filter", uuid: UUID, room: getRoomName() }));
  };

  const handleMenu = useCallback((event) => {
    const { keyCode } = event;

    if (keyCode === 190) {
      //console.log(`Visibility: ${visibility}`);
      setVisibility(true);
    }

    if (keyCode === 27) {
      //console.log(`Visibility: ${visibility}`);
      setVisibility(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleMenu);

    return () => {
      window.removeEventListener("keydown", handleMenu);
    };
  }, []);

  return (
    <div className="select-data-container" style={visibility ? { display: "block", position: "fixed", top: "10vh", left: "40vw", width: "20%" } : { display: "none" }}>
      <Select defaultValue={selectedOption} onChange={(e) => updateFilters(e)} options={filterOptions} />
    </div>
  );
};

export default SelectData;
