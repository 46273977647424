import { UserContextProvider } from "./Contexts/User";
import { WebSocketContextProvider } from "./Contexts/WebSocket";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Username from "./Components/Poker/Username/Username";
import Choose from "./Components/Poker/Choose/Choose";
import Table from "./Components/Poker/Table/Table";
import Homepage from "./Components/Homepage/Homepage";
import PokerLanding from "./Components/Poker/Landing/Landing";
import Footer from "./Components/Footer/Footer";
import SelectData from "./Components/Poker/SelectData/SelectData";
import BlogLanding from "./Components/Blogs/Landing/Landing";
import BlogPage from "./Components/Blogs/Page/Page";
import OurTeam from "./Components/OurTeam/OurTeam";
import MobileIssue from "./Components/Poker/MobileIssue/MobileIssue";
import MobileTable from "./Components/Poker/MobileTable/MobileTable";
import { useWindowSize, isDesktop, setWindowTitle } from "./Common/Utils";

import "./App.css";

const EntryPoint = () => {

  setWindowTitle('Planning Poker');

  const size = useWindowSize();

  return (
    <WebSocketContextProvider>
      <div className="poker-board">
        <Username />
        {isDesktop(size) ? (
          <>
            <Table />
            <div>
              <SelectData />
            </div>
            <Choose />
          </>
        ) : (
          <>
            <MobileIssue />
            <Choose />
            <MobileTable />
          </>
        )}
        <Footer route={'poker'} />
      </div>
    </WebSocketContextProvider>
  );
};

const App = () => {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/poker" element={<PokerLanding />}></Route>
          <Route path="/poker/room/:id" element={<EntryPoint />}></Route>
          <Route path="/blogs" element={<BlogLanding />}></Route>
          <Route path="/blogs/:id" element={<BlogPage />}></Route>
          <Route path="/our-team" element={<OurTeam />}></Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default App;
