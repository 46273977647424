import React from "react";
import "./Tooltip.scss";

function Medium() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 16 16"
      version="1.1"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      width="16"
      height="16"
    >
      <path
        d="M3 4h10c.6 0 1 .4 1 1s-.4 1-1 1H3c-.6 0-1-.4-1-1s.4-1 1-1zm0 6h10c.6 0 1 .4 1 1s-.4 1-1 1H3c-.6 0-1-.4-1-1s.4-1 1-1z"
        fill="#FFAB00"
      ></path>
    </svg>
  );
}

function Highest() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16"
      height="16">
      <path
        fill="#ff5630"
        d="M3.479 7.9c-.5.3-1.1.1-1.4-.4s-.1-1 .4-1.3l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.2.4-.8.6-1.3.3l-4.5-2.7-4.5 2.7z"
      ></path>
      <path
        fill="#ff7452"
        d="M3.479 12.2c-.5.3-1.1.2-1.4-.3s-.1-1.1.4-1.4l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3l-4.4-2.7-4.5 2.7z"
      ></path>
    </svg>
  );
}
function High() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16"
      height="16">
      <path
        fill="#ff5630"
        d="M3.5 9.9c-.5.3-1.1.1-1.4-.3s-.1-1.1.4-1.4l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3L8 7.2 3.5 9.9z"
      ></path>
    </svg>
  );
}

function Low() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16"
      height="16">
      <path
        fill="#0065ff"
        d="M12.5 6.1c.5-.3 1.1-.1 1.4.4.3.5.1 1.1-.3 1.3l-5 3c-.3.2-.7.2-1 0l-5-3c-.6-.2-.7-.9-.4-1.3.2-.5.9-.7 1.3-.4L8 8.8l4.5-2.7z"
      ></path>
    </svg>
  );
}

function Lowest() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16"
      height="16">
      <path
        fill="#0065ff"
        d="M12.505 8.145c.5-.3 1.1-.1 1.4.4s.1 1-.4 1.3l-5 3c-.3.2-.7.2-1 0l-5-3c-.5-.3-.6-.9-.3-1.4.2-.4.8-.6 1.3-.3l4.5 2.7 4.5-2.7z"
      ></path>
      <path
        fill="#2684ff"
        d="M12.505 3.845c.5-.3 1.1-.2 1.4.3s.1 1.1-.4 1.4l-5 3c-.3.2-.7.2-1 0l-5-3c-.5-.3-.6-.9-.3-1.4.3-.5.9-.6 1.4-.3l4.4 2.7 4.5-2.7z"
      ></path>
    </svg>
  );
}


const PriorityType = (props: any) => {
  const { issue } = props;

  let issueType = Medium();

  switch (issue.fields.priority.name) {
    case "Highest":
      issueType = Highest();
      break;
    case "High":
      issueType = High();
      break;
    case "Lowest":
      issueType = Lowest();
      break;
    case "Low":
      issueType = Low();
      break;
    default:
      issueType = Medium();
  };

  return (
    <div style={{ marginTop: "2px" }} className="tooltip">
      {issueType}
      <span className="tooltiptext">{issue.fields.priority.name}</span>
    </div>
  );
};

export default PriorityType;